import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useMemo, useState } from 'react';
import {
  MdOutlineCleaningServices,
  MdOutlineSettings,
  MdOutlineNotifications,
  MdOutlineCenterFocusStrong,
  MdOutlineChat,
  MdOutlineFeaturedVideo,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import styles from './ControlBar.module.scss';
import Notifications from './Notifications';

import actions from '@/actions';
import SettingsModal from '@/components/modals/Settings';
import Bar from '@/components/ui/Bar';
import OlMap from '@/helpers/OlMap';
import EventEmitter from '@/libs/EventEmitter';
import { ModalService as modal } from '@/libs/Modal';
import { ToastService as toast } from '@/libs/Toast';

const cx = classNames.bind(styles);
const defaultOptions = {
  settings: false,
};

const ControlBar = ({ robot, options = defaultOptions }) => {
  const dispatch = useDispatch();
  const activeRobotIds = useSelector((state) => state.robot.activeRobotIds);
  const robotOptions = useSelector((state) => state.robot.options[robot.id]);
  const focusRobotId = useSelector((state) => state.robot.focusRobotId);
  const notifications = useSelector((state) => state.notification[robot.id]);
  const [isOpenNotifications, setIsOpenNotifications] = useState(false);

  // 연결 여부
  const isActive = useMemo(() => activeRobotIds.includes(robot.id), [activeRobotIds]);

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robot.id}/event/openNotifications`, setIsOpenNotifications);

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  useUpdateEffect(() => {
    // 로봇 연결 해제된 경우
    if (!isActive) {
      setIsOpenNotifications(false);
    }
  }, [activeRobotIds]);

  const toggleFocus = (e) => {
    e.stopPropagation();

    // 지도 내 해당 로봇 마커 존재하지 않는 경우
    if (!OlMap.getMap().getOverlayById(`robots/${robot.id}`)) {
      toast.error('Location has not been defined yet.');
      return;
    }

    if (focusRobotId === robot.id) {
      dispatch(actions.robot.setFocus());
    } else {
      dispatch(actions.robot.setFocus(robot.id));
    }
  };

  const toggleOption = (e) => {
    e.stopPropagation();

    const { option } = e.currentTarget.dataset;
    dispatch(actions.robot.toggleOption(robot.id, option));
  };

  const removeTraces = (e) => {
    e.stopPropagation();

    OlMap.removeShoot(robot.id);
    OlMap.removeFootprint(robot.id);
    OlMap.removeIoTHubEvent(robot.id);
  };

  const toggleNotifications = (e) => {
    e.stopPropagation();
    setIsOpenNotifications(!isOpenNotifications);
  };

  const openSettings = (e) => {
    e.stopPropagation();
    modal.show(SettingsModal, { robot });
  };

  const hasVideo = useMemo(() => {
    return (
      Object.hasOwn(robot.metadata, 'VIDEO_TYPE') &&
      ['Kurento', 'WebRTC', 'RTSP'].includes(robot.metadata['VIDEO_TYPE'])
    );
  }, [robot]);

  if (!isActive) return;

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <div className={cx('side')}>
          <div className={cx(['button', { active: isOpenNotifications }])} onClick={toggleNotifications}>
            <MdOutlineNotifications size={16} title="Notifications" />
            <div className={cx('value')}>{notifications?.length ?? 0}</div>
          </div>
          {robot.isOwned && options.settings && (
            <div className={cx('button')} onClick={openSettings}>
              <MdOutlineSettings size={16} title="Settings" />
            </div>
          )}
        </div>
        <div className={cx('side')}>
          <div className={cx('button')} onClick={removeTraces}>
            <MdOutlineCleaningServices size={16} title="Remove Traces" />
          </div>
          <Bar />
          <div className={cx(['button', { active: focusRobotId === robot.id }])} onClick={toggleFocus}>
            <MdOutlineCenterFocusStrong size={16} title="Set Focus" />
          </div>
          <div data-option="label" className={cx(['button', { active: robotOptions.label }])} onClick={toggleOption}>
            <MdOutlineChat size={16} title="Show Label" />
          </div>
          {hasVideo && (
            <div data-option="video" className={cx(['button', { active: robotOptions.video }])} onClick={toggleOption}>
              <MdOutlineFeaturedVideo size={16} title="Show Video" />
            </div>
          )}
        </div>
      </div>
      <Notifications robotId={robot.id} open={isOpenNotifications} />
    </div>
  );
};

export default ControlBar;
