import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useContext, useState, useMemo } from 'react';
import { RiSearchLine } from 'react-icons/ri';

import styles from './index.module.scss';

import EmptyList from '@/components/ui/EmptyList';
import { MessageContext } from '@/helpers/MessageProvider';
import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const TYPE = {
  1: 'UINT8',
  2: 'INT8',
  3: 'UINT16',
  4: 'INT16',
  5: 'UINT32',
  6: 'INT32',
  7: 'UINT64',
  8: 'INT64',
  9: 'REAL32',
  10: 'REAL64',
};

const Parameters = ({ robot }) => {
  const { publishCommand } = useContext(MessageContext);
  const [paramValue, setParamValue] = useState();
  const [keyword, setKeyword] = useState('');

  useMountEffect(() => {
    publishCommand(robot, 'param/list', [[]]);

    const subscribeToken = EventEmitter.subscribe(`${robot.id}/telemetry/paramValue`, (data) => {
      setParamValue(data);
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  const handleChange = (e) => {
    setKeyword(e.target.value);
  };

  const params = useMemo(() => {
    if (!paramValue) return [];

    return Object.values(paramValue).filter((param) => {
      if (keyword.length > 0) {
        const paramId = param.paramId.toLowerCase();
        const lowerCase = keyword.toLowerCase();

        return paramId.includes(lowerCase);
      }
      return param;
    });
  }, [paramValue, keyword]);

  return (
    <div className={cx('container')}>
      <div className={cx('toolbar')}>
        <RiSearchLine size={18} color="white" title="Address" />
        <input type="text" placeholder="ID" onChange={handleChange} className={cx('field')} />
      </div>
      <div className={cx(['row', 'header'])}>
        <div className={cx(['index', 'center'])}>Index</div>
        <div className={cx('id')}>ID</div>
        <div className={cx(['value', 'center'])}>Value</div>
        <div className={cx(['type', 'center'])}>Type</div>
      </div>
      <div className={cx('body')}>
        {params.length === 0 && (
          <div className={cx('empty')}>
            <EmptyList />
          </div>
        )}
        {params.map((param, index) => (
          <div key={index} className={cx('row')}>
            <div className={cx(['index', 'center'])}>{param.paramIndex}</div>
            <div className={cx('id')}>{param.paramId}</div>
            <div className={cx(['value', 'center'])}>{param.paramValue}</div>
            <div className={cx(['type', 'center'])}>{TYPE[param.paramType]}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Parameters;
