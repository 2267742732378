import React from 'react';

import Body from './Body';
import Mode from './Mode';
import Status from './Status';

import { Frame, ControlBar } from '@/components/ui/Dashboard';

const Dashboard = ({ data: robot }) => {
  return (
    <Frame
      robot={robot}
      mode={<Mode robotId={robot.id} />}
      status={<Status robotId={robot.id} />}
      controlBar={<ControlBar robot={robot} options={{ settings: false }} />}>
      <Body robotId={robot.id} />
    </Frame>
  );
};

export default Dashboard;
